.frontpage {
    min-height: 100vh;
    color: #9FB0CF;
  }

@media (min-width: 600px) {
.frontpage-contents {
      margin-left: 125px;
      padding-top: 325px;
    }
  }
  
.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }